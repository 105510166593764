import React, { useState } from 'react';
import './Contact.css'; // import your custom CSS file here
import emailjs from 'emailjs-com';
import Footer from '../Footer/Footer';



const Contact = () => {
  emailjs.init("zuzvjxWIfuhflQzJt");

  const [isSuccess, setIsSuccess] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: '',
    number: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    emailjs.send("service_y5ny42o", "template_lxieqxt", formData, "zdeSFPU5kllmn0iOn")
      .then((result) => {
        console.log(result.text);
        setIsSuccess(true);
      }, (error) => {
        console.log(error.text);
      });

    setFormData({
      name: '',
      email: '',
      message: '',
      number: ''
    });
  };

  return (
    <>
      <div className="contact-form">
        <h2>Contact Us</h2>
        {isSuccess && <p className="success-message">Message sent successfully!</p>}
        <form onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name">Name</label>
            <input type="text" id="name" name="name" value={formData.name} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input type="email" id="email" name="email" value={formData.email} onChange={handleChange} required />
          </div>
          <div className="form-group">
            <label htmlFor="number">Phone Number</label>
            <textarea id="number" name="number" value={formData.number} onChange={handleChange}></textarea>
          </div>
          <div className="form-group">
            <label htmlFor="message">Message</label>
            <textarea id="message" name="message" value={formData.message} onChange={handleChange} required></textarea>
          </div>
          <button type="submit">Send</button>
        </form>
      </div>
      <div>
        <Footer />
      </div>
    </>
  );
};

export default Contact;
