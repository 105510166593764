import React, { useRef, useEffect } from 'react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
import './TestimonialSlider.css';

// import image1 from '../../assets/image_1.jpeg';
// import image2 from '../../assets/image_1.jpeg';
// import image3 from '../../assets/image_1.jpeg';
// import image4 from '../../assets/image_1.jpeg';
// import image5 from '../../assets/image_1.jpeg';

const TestimonialSlider = () => {
  const sliderRef = useRef();

  useEffect(() => {
    const intervalId = setInterval(() => {
      sliderRef.current?.element?.current?.startAutoPlay();
    }, 1000);
    return () => clearInterval(intervalId);
  }, []);
  return (
    <AwesomeSlider className="slider" ref={sliderRef}>
      <div className="testimonial">
        {/* <div className="testimonial-image">
          <img src={image1} alt="testimonial" />
        </div> */}
        <div className="testimonial-content">
          <blockquote>
            "Tor-Ex did an amazing job on our excavation project. They worked efficiently and left the site clean and organized. Highly recommended!"
          </blockquote>
          <div className="testimonial-stars">
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
          </div>
        </div>
      </div>
      <div className="testimonial">
        {/* <div className="testimonial-image">
          <img src={image2} alt="testimonial" />
        </div> */}
        <div className="testimonial-content">
          <blockquote>
            "We were impressed with the quality of work and attention to detail from Tor-Ex. They exceeded our expectations and we couldn't be happier with the results."
          </blockquote>
          <div className="testimonial-stars">
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
          </div>
        </div>
      </div>
      <div className="testimonial">
        {/* <div className="testimonial-image">
          <img src={image3} alt="testimonial" />
        </div> */}
        <div className="testimonial-content">
          <blockquote>
            "The team at Tor-Ex were professional and courteous throughout the entire project. They delivered top-quality work and left the site cleaner than when they arrived."
          </blockquote>
          <div className="testimonial-stars">
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
          </div>
        </div>
      </div>
      <div className="testimonial">
        {/* <div className="testimonial-image">
          <img src={image4} alt="testimonial" />
        </div> */}
        <div className="testimonial-content">
          <blockquote>
            "Tor-Ex completed our excavation project on time and within budget. They were a pleasure to work with and we would highly recommend them for any excavation needs."
          </blockquote>
          <div className="testimonial-stars">
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
          </div>
        </div>
      </div>
      <div className="testimonial">
        {/* <div className="testimonial-image">
          <img src={image5} alt="testimonial" />
        </div> */}
        <div className="testimonial-content">
          <blockquote>
            "We were impressed with Tor-Ex's attention to safety and their commitment to quality work. They completed the excavation project with minimal disruption to our operations and left the site in excellent condition."
          </blockquote>
          <div className="testimonial-stars">
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
            <span>&#9733;</span>
          </div>
        </div>
      </div>
    </AwesomeSlider>
  );
}

export default TestimonialSlider;
