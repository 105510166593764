import React from 'react'
import './AboutUs.css'
import image_2 from '../../assets/2.webp'
import Footer from '../Footer/Footer'

const AboutUs = () => {
  return (
    <>
      <div className="about-us">
        <div className="image-container">
          <img src={image_2} alt="About Us" />
        </div>
        <div className="description-container">
          <h2 className="about-title">ABOUT US</h2>
          <p className="description"> TOR-EX is a water main company located in Toronto, Canada. Our team is dedicated to providing high-quality water main installation, repair, and maintenance services to residential and commercial properties throughout the city.</p>
          <p className="description">Our team of certified professionals has years of experience in the water main industry, and we are committed to using the latest technology and techniques to ensure that our clients receive the best possible service. We are also dedicated to being environmentally responsible, and we use eco-friendly materials and methods whenever possible. </p>
          <p className="description">At TOR-EX, we understand that a functioning water main is essential to the health and well-being of any property, which is why we offer 24/7 emergency services to help our clients in their time of need. We are proud to be a trusted partner for many of Toronto's leading property management companies, and we are committed to delivering the best possible service to every one of our clients.</p>
        </div>
      </div>
      <div>
        <Footer />
      </div>
    </>

  )
}

export default AboutUs