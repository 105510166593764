import './App.css';
import React, { useState, useEffect } from 'react';
import Navbar from './components/NavBar/Navbar';
import StickyNavbar from './components/NavBar/StickyNavbar';
import VideoComponent from './components/VideoComponent/VideoComponent';
import AboutUs from './components/About/AboutUs';
import Contact from './components/Contact/Contact';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  const [isSticky, setIsSticky] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 992) {
        setIsSticky(true);
      } else {
        setIsSticky(false);
      }
    };

    handleResize();

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  return (
    <>
      {isSticky && <StickyNavbar />}
      <Navbar />
      <Router>
      <Routes>
        <Route path="/" element={<VideoComponent />} />
        <Route path="/about" element={<AboutUs />} />
        <Route path="/contact" element={<Contact />} />
      </Routes>
    </Router>
    </>
  );
}

export default App;
