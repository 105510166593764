import React from 'react'
import { Nav } from 'react-bootstrap'
import { FaFacebook, FaInstagram } from 'react-icons/fa'
import './Footer.css'

const Footer = () => {
  return (
    <>
      <footer className="footer">
        <div className="footer-column">
          <h3>Contact Us</h3>
          <ul>
            <li>Phone: (416) 523-7327</li>
            <li>Email: info@tor-ex.ca</li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Offices</h3>
          <ul>
            <li>Toronto, ON</li>
          </ul>
        </div>
        <div className="footer-column">
          <h3>Connect with us</h3>
          <ul>
            <Nav>
              <Nav.Item className="footer-column">
                <Nav.Link href="https://www.facebook.com/TorontoExcavations/"><FaFacebook /></Nav.Link>
                <Nav.Link href="https://www.instagram.com/torontoexcavations/?hl=en"><FaInstagram /></Nav.Link>
              </Nav.Item>
            </Nav>
          </ul>
        </div>

      </footer>
      {/* <div style={{ margin: 0, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
        <Nav style={{ margin: 0, fontSize: '12px', display: 'flex', alignItems: 'center'}}>
          <Nav.Link href="https://www.nadernasr.ca" target="_blank" rel="noopener noreferrer" style={{ textDecoration: 'none', color: 'white' }}>
            <span style={{ backgroundColor: '#444', padding: '4px 8px', borderRadius: '4px', fontSize: '8px' }}>Created by Nader</span>
          </Nav.Link>
        </Nav>
      </div> */}
    </>
  )
}

export default Footer