import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar as Navigation, Nav, Container } from 'react-bootstrap';
import logo from '../../assets/Ellipse 1.png';
import './Navbar.css'

const Navbar = () => {
  return (
    <Navigation bg="success" variant="dark" expand="lg" style={{ color: 'white' }}>
      <Container>
      <Navigation.Brand href="/">
          <img src={logo} alt="My Website" height="60" className="d-inline-block align-top" />
        </Navigation.Brand>
        <Navigation.Toggle aria-controls="navbar-nav" />
        <Navigation.Collapse id="navbar-nav">
          <Nav className="ms-auto">
            <Nav.Link href="/">Home</Nav.Link>
            <Nav.Link href="/about">About</Nav.Link>
            <Nav.Link href="/contact">Contact</Nav.Link>
          </Nav>
        </Navigation.Collapse>
      </Container>
    </Navigation>
  );
}

export default Navbar;
