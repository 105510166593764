import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FaFacebook, FaInstagram, FaPhone, FaEnvelope, FaClock } from 'react-icons/fa';
import './StickNavbar.css'

function StickyNavbar() {
  return (
    <Navbar bg="light" expand="lg" className="sticky-top">
      <Container className="justify-content-center">
        <Navbar.Toggle aria-controls="navbar-nav" />
        <Navbar.Collapse id="navbar-nav">
          <Nav className="me-auto">
            <Nav.Item>
              <Nav.Link href="tel:+14165237327" target="_blank" rel="noopener noreferrer">
                <FaPhone style={{ fontSize: '20px', marginRight: '5px', transform: 'scaleX(-1)' }} />
                Phone: (416) 523-7327
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="mailto:info@tor-ex.ca" target="_blank" rel="noopener noreferrer" >
                <FaEnvelope style={{ fontSize: '10px', marginRight: '5px' }} />
                Email: info@tor-ex.ca
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link><FaClock style={{ fontSize: '20px', padding: '5px' }} />Open Times: Mon-Sat 8am-5:30pm, Sunday - Closed</Nav.Link>
            </Nav.Item>
          </Nav>
          <Nav>
            <Nav.Item>
              <Nav.Link href="https://www.facebook.com/TorontoExcavations/"><FaFacebook /></Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link href="https://www.instagram.com/torontoexcavations/?hl=en"><FaInstagram /></Nav.Link>
            </Nav.Item>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
}

export default StickyNavbar;
