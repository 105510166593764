import React from 'react';
import './BoxComponent.css';
import image1 from '../../assets/will.jpg'; // import your image file here

const BoxComponent = () => {
  return (
    <div className="will-container">
      <div className="will">
        <div className="will-image">
          <img src={image1} alt="will" />
        </div>
        <div className="will-content">
          <h2 className='will-title'>Our Mission and Commitment</h2>
          <p className='sub-title'>Target Zero Incidents and Public Protection Programs, our top priority.</p>
          <div className="will-button">
            <a className="contact-button" href="/contact">Contact Us</a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BoxComponent;
