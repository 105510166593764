import React from 'react'
import './VideoComponent.css';
import Services from '../Services/Services';
import Projects from '../Projects/Projects';
import TestimonialSlider from '../Testimonials/TestimonialSlider';
import ReactPlayer from 'react-player'
import Footer from '../Footer/Footer';

const VideoComponent = () => {

  return (
    <>
      <div className='player-wrapper'>
        <ReactPlayer
          className='react-player'
          url='https://vimeo.com/865855058?share=copy'
          width='100%'
          height='100%'
          playsinline={true}
          playing={true}
          autoplay={true}
          loop={true}
          muted={true}
          config={{ file: { attributes: { playsinline: true, }, }, }}
        />
      </div>
      <TestimonialSlider />
      <Services />
      <Projects />
      <Footer />

    </>
  );
}

export default VideoComponent