import React from 'react';
import './Projects.css';

const Projects = () => {


  const images = [
    {
      id: 1,
      title: 'Civil Construction',
      description: 'Our mission at TOR-EX from day one has been to ensure the satisfaction of our client.',
      url: 'https://images.unsplash.com/photo-1603465409624-b152d6fe0339?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3870&q=80'
    },
    {
      id: 2,
      title: 'Hydrovac',
      description: 'Our operating procedures commit our crews to ensure our sites wrap up safe, fast and always to spec.',
      url: 'https://images.unsplash.com/photo-1583716210215-d087531d154d?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=3774&q=80'
    },
    {
      id: 3,
      title: 'Project Management',
      description: 'As the construction industry modernizes we strive to implement the most efficient working methods to further separate our performance from our competition.',
      url: 'https://images.unsplash.com/photo-1541888946425-d81bb19240f5?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=2370&q=80'
    }
  ];

  return (
    <>
      <div className="image-grid">
      {images.map((image) => (
        <div
          key={image.id}
          className='image-wrapper'
        >
          <img src={image.url} alt={image.title} className="image" />
          <div className="image-info">
            <h3 className="image-title">{image.title}</h3>
            <p className="image-description">{image.description}</p>
          </div>
        </div>
      ))}
    </div>
    </>
  );
}

export default Projects;
