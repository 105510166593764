import React from 'react';
import './Services.css';
import image1 from '../../assets/image_1.jpeg'; // import your image files here
import image2 from '../../assets/image_2.jpeg';
import image3 from '../../assets/image_3.jpeg';
import image4 from '../../assets/image_4.jpeg';
import BoxComponent from './BoxComponent';

const Services = () => {
  return (
    <div className='services-container'>
      <h1 className='title'>Tor-Ex Cutting-edge Services</h1>

      <div className="card">
        <div className="card-body">
          <p className="card-text"><strong>Installation of new water mains:</strong> We handle the installation of new water mains for both commercial and residential properties.</p>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <p className="card-text"><strong>Removal of water services:</strong> We provide service disconnections and abandonments for properties that no longer require a water service.</p>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <p className="card-text"><strong>Water main breaks:</strong> We offer repair services for water mains that have suffered breaks or damage.</p>
        </div>
      </div>

      <div className="card">
        <div className="card-body">
          <p className="card-text"><strong>Installation of valves and hydrants:</strong> We provide installation of valves and hydrants, which are used to control the flow of water in the main and provide access for firefighting.</p>
        </div>
      </div>

      <div className="card" style={{marginBottom:'50px'}}>
        <div className="card-body">
          <p className="card-text"><strong>Hydrovac excavation:</strong> We offer a safe and efficient method of excavation using high-pressure water and vacuum to expose underground utilities and structures. This service is available for commercial and residential properties.</p>
        </div>
      </div>

      {/* <p>Thank you for considering our services. We look forward to working with you to meet your water main needs.</p> */}

      <div className="box-container">
        <div className="box">
          <div className="box-image">
            <img src={image1} alt="Box 1" />
            <div className="box-overlay">
              <h2>Civil</h2>
            </div>
          </div>
        </div>
        <div className="box">
          <div className="box-image">
            <img src={image2} alt="Box 2" />
            <div className="box-overlay">
              <h2>Hydrovac</h2>
            </div>
          </div>
        </div>
        <div className="box">
          <div className="box-image">
            <img src={image3} alt="Box 3" />
            <div className="box-overlay">
              <h2>Excavation</h2>
            </div>
          </div>
        </div>
        <div className="box">
          <div className="box-image">
            <img src={image4} alt="Box 4" />
            <div className="box-overlay">
              <h2>Project Management</h2>
            </div>
          </div>
        </div>
      </div>

      <BoxComponent />
    </div>
  );
}

export default Services;
